<template>
<b-overlay :show="loading">
  <AuthWrapper>
    <template #card>
      <div class="text-center">
        <div>
          <h1>We are glad to have you back</h1>
        </div>
        <div>
          <p>Kindly provide the details below to get started</p>
        </div>
      </div>
      <div class="px-3">
        <validation-observer ref="registerForm" #default="{invalid}">
          <b-form @submit.prevent="signup">
            <!--                username -->
            <b-form-group label="Email" label-for="email">
              <validation-provider #default="{ errors }" name="Email" rules="required|email" vid="email">
                <div class="exxtra-input">
                  <span class="leading">
                    <RegistrationMail />
                  </span>
                  <input id="login-username" v-model="registration.email" type="email" name="register-email" placeholder="brucewayne@exxtra.app" />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--                Phone -->
            <b-form-group label="Phone" label-for="phone">
              <validation-provider #default="{ errors }" name="Phone" rules="required|length:10" vid="phone">
                <div class="exxtra-input">
                  <span class="leading">
                    <RegistrationPhone />
                  </span>
                  <input id="phone" v-model="registration.phone" name="register-phone" placeholder="0201234567" />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--                password -->
            <b-form-group label="Password" label-for="password">

              <validation-provider #default="{ errors }" name="Password" rules="required|password">
                <div class="exxtra-input">
                  <span class="leading">
                    <PasswordLock />
                  </span>
                  <input id="login-password" v-model="registration.password" :type="password1FieldType" placeholder="············">
                  <span class="trailing">
                    <feather-icon class="cursor-pointer" :icon="password1ToggleIcon" @click="togglePassword1Visibility" />
                  </span>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!--                  Confirm password -->
            <b-form-group label="Confirm Password" label-for="confirm-password">

              <validation-provider #default="{ errors }" name="Confirm Password" rules="required|confirmed:Password">
                <div class="exxtra-input">
                  <span class="leading">
                    <PasswordLock />
                  </span>
                  <input id="confirm-password" v-model="Cpassword" :type="password2FieldType" placeholder="············">
                  <span class="trailing">
                    <feather-icon class="cursor-pointer" :icon="password2ToggleIcon" @click="togglePassword2Visibility" />
                  </span>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="my-1">
              <validation-provider #default="{ errors }" name="Please agree to the terms before proceeding" rules="required|terms-and-conditions">
                <b-form-checkbox id="register-privacy-policy" v-model="registration.agree_to_terms" name="checkbox">
                  <small>
                    By submitting this form, I agree to the <b-link :to="{ name: 'terms' }" target="_blank" style="text-decoration: underline">
                    terms and conditions</b-link>
                    of the loan and I acknowledge that my information will be used in
                    accordance with EXXTRA's <b-link :to="{ name: 'privacy' }" target="_blank" style="text-decoration: underline">
                    privacy policy.</b-link>
                  </small>
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div>
              <b-button class="py-1" type="submit" variant="primary" block :disabled="invalid">
                Sign Up
              </b-button>
            </div>

            <div class="text-center">
              <p class="pt-2"> Already have an account?
                <span>
                  <b-link :to="{ name:'auth-login' }">
                    <span>Sign In</span>
                  </b-link>
                </span>
              </p>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </AuthWrapper>
</b-overlay>
</template>

<script>
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import {
  email,
  required
} from '@validations';
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import PasswordLock from "@/assets/svg/PasswordLock.svg";
import TurtleRentLogo from '@core/layouts/components/Logo.vue';
import RegistrationMail from "@/assets/svg/RegistrationMail.svg";
import {
  togglePasswordVisibility
} from '@core/mixins/ui/forms';
import RegistrationPhone from "@/assets/svg/RegistrationPhone.svg";
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay
} from 'bootstrap-vue'
import {
  get
} from "lodash"
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'

export default {
  components: {
    AuthWrapper,
    PasswordLock,
    RegistrationPhone,
    RegistrationMail,
    Rectangles,
    BottomDesign,
    BOverlay,
    BAlert,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BFormSelect,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    TurtleRentLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      errorMessage: '',
      client_group_options: [],
      registration: {
        email: '',
        phone: '',
        password: '',
        agree_to_terms: '',
      },
      Cpassword: '',

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

      // validation rules
      required,
      email
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    const {
      referrer = null
    } = this.$route.query
    if (referrer) {
      this.registration.referrer = referrer;
      this.$router.replace({
        query: {}
      })
    }
  },
  methods: {
    async signup() {
      try {
        this.loading = true;
        this.errorMessage = '';

        const success = await this.$refs.registerForm.validate();
        if (!success) {
          return;
        }

        const formData = new FormData();

        Object.keys(this.registration)
          .forEach(field_name => {
            formData.append(field_name, this.registration[field_name]);
          });

        await this.useJwt()
          .authService
          .register(formData);

        sessionStorage.setItem("registration_email", this.registration.email)

        this.$router.replace({
          name: "auth-register-confirm-email"
        }).catch(() => {})
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        if (error_message.includes("An account with this email already exists.")) {
          this.$refs.registerForm.setErrors({
            email: [error_message]
          });
        }

        if (error_message.includes("An account with this phone number already exists.")) {
          this.$refs.registerForm.setErrors({
            phone: [error_message]
          });
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
